import React from "react";
import Button from "components/Button";

import EmbeddedImage from "components/EmbeddedImage";
import ModalNavigation from "components/ModalNavigation";

import constants from "constants/fi.json";

import  { UserSelection } from "utils/useStore";
import analytics from "utils/analytics";

import styles from "./PackModalContent.module.css";

interface Props {
  closeModal: React.MouseEventHandler<HTMLDivElement>;
  setUrn: (urn: UserSelection) => void;
  urn: any;
  browse: (direction: 1 | -1) => void;
  dontChangeState?: boolean;
}

const Modal = ({ urn, browse, setUrn, closeModal, dontChangeState }: Props) => {
  const price = urn.price;
  const title = urn.title.rendered;

  return (
    <div className={styles.wrapper}>
      {title && (
        <div className={styles.toprow}>
          <span className={[styles.title, "h2"].join(" ")}>{title}</span>
          {price && <span className={styles.price}>, {price} €</span>}
        </div>
      )}
      {urn.content.rendered && (
        <div
          className={styles.content}
          dangerouslySetInnerHTML={{ __html: urn.content.rendered }}
        />
      )}

      <EmbeddedImage post={urn} size="medium" zoomable />
      <Button
        onClick={(e) => {
          analytics.pushSelection({
            selectionTitle: "Urn",
            selectionValue: title,
          });

          setUrn({ id: urn.id, title, price: price });

          closeModal(e);
        }}
        fullWidth
        topGap
      >
        {constants.choose_and_continue}
      </Button>
      <ModalNavigation browse={browse} />
    </div>
  );
};

export default Modal;
