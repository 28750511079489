import React, { useState } from "react";
import Button from "components/Button";

import EmbeddedImage from "components/EmbeddedImage";

import styles from "./PackModalContent.module.css";

import constants from "constants/fi.json";
import ModalNavigation from "components/ModalNavigation";
import CheckboxList from "components/CheckboxList";
import useStore, { UserSelection, SelectableItem } from "utils/useStore";
import analytics from "utils/analytics";

type CoffinOptionsSelection = SelectableItem[] | null;

interface Props {
  closeModal: React.MouseEventHandler<HTMLDivElement>;
  setCoffin: (coffin: UserSelection) => void;
  setCoffinOptions: (coffinOption: CoffinOptionsSelection) => void;
  coffin: any;
  browse: (direction: 1 | -1) => void;
  selected: CoffinOptionsSelection;
}

const useStoredCoffinOptions = (coffin: any) => {
  const storedCoffinOptions = useStore((state) => state.coffinOptions);
  const storedCoffin = useStore((state) => state.coffin);

  if (!storedCoffinOptions || storedCoffin?.id !== coffin.id) {
    return [];
  }

  return storedCoffinOptions.map((option) => ({
    id: Number(option?.id || ""),
    title: option?.title || "",
    price: option?.price,
  }));
};

const Modal = ({
  coffin,
  browse,
  setCoffin,
  closeModal,
  setCoffinOptions,
  selected,
}: Props) => {
  const setUserSelection = useStore((state) => state.setUserSelection);
  const storedCoffinOptions = useStoredCoffinOptions(coffin);
  const [userOption, setUserOption] = useState<CoffinOptionsSelection>(
    selected || storedCoffinOptions
  );
  const price = coffin.price as string;
  const title = coffin.title.rendered as string;

  const recommendedUrns = coffin.acf?.coffin_recommended_urn || null;

  const toggleUserOption = (selection: SelectableItem) => {
    setUserOption((oldValue) => {
      if (!oldValue) {
        return [selection];
      }

      const filteredValue = oldValue.filter((opt) => opt?.id !== selection?.id);

      if (filteredValue.length !== oldValue.length) {
        return filteredValue;
      }

      return [...oldValue, selection];
    });
  };

  return (
    <div className={styles.wrapper}>
      {title && (
        <div className={styles.toprow}>
          <span className={[styles.title, "h2"].join(" ")}>{title}</span>
          {price && <span className={styles.price}>, {price} €</span>}
        </div>
      )}

      {coffin.content.rendered && (
        <div
          className={styles.content}
          dangerouslySetInnerHTML={{ __html: coffin.content.rendered }}
        />
      )}

      <EmbeddedImage post={coffin} size="medium" zoomable />

      <CheckboxList
        select={toggleUserOption}
        selected={userOption}
        options={coffin.options}
        title={constants.coffin_extra}
      />

      <Button
        onClick={(e) => {
          setUserSelection("recommendedUrns", recommendedUrns);

          analytics.pushSelection({
            selectionTitle: "Coffin",
            selectionValue: title,
          });

          if (userOption) {
            analytics.pushSelection({
              selectionTitle: "Coffin options",
              selectionValue: userOption.map((opt) => opt.title).join(", "),
            });
          }

          setCoffin({
            id: coffin.id,
            title,
            price,
          });
          setCoffinOptions(userOption ? userOption : []);

          closeModal(e);
        }}
        fullWidth
        topGap
      >
        {constants.choose_and_continue}
      </Button>
      <ModalNavigation browse={browse} />
    </div>
  );
};

export default Modal;
