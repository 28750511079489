import React, { useState } from "react";
import { UserSelection } from "utils/useStore";
import PackModalContent from "./PackModalContent";
import useMappedClothingPrices from "./useMappedClothingPrices";

const useClothing = () => {
  const clothings = useMappedClothingPrices();

  const [openModalIndex, setOpenModalIndex] = useState<number | null>(null);

  const ModalContent = ({
    closeModal,
    setClothing,
    dontChangeState,
  }: {
    closeModal: React.MouseEventHandler<HTMLDivElement>;
    setClothing: (clothing: UserSelection) => void;
    dontChangeState?: boolean;
  }) => (
    <>
      {openModalIndex !== null && (
        <PackModalContent
          clothing={clothings[openModalIndex]}
          closeModal={closeModal}
          dontChangeState={dontChangeState}
          setClothing={setClothing}
          browse={(newDirection: 1 | -1) => {
            const newIndex = openModalIndex + newDirection;
            if (newIndex >= 0 && newIndex < clothings.length) {
              setOpenModalIndex(newIndex);
            }
          }}
        />
      )}
    </>
  );

  return [clothings, setOpenModalIndex, openModalIndex, ModalContent] as const;
};

export default useClothing;
