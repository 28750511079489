import { useState } from "react";
import { AnimatePresence } from "framer-motion";

import Button from "components/Button";
import usePhase from "utils/usePhase";

import styles from "./summary.module.css";
import Modal from "components/Modal";

import submitUnfinishedRequest from "utils/submitUnfinishedRequest";

interface ShareSummaryProps {
  data: Record<string, any>;
}

const ShareSummary = ({ data }: ShareSummaryProps) => {
  const t = usePhase("summary");

  const [linkHasBeenCopied, setLinkHasBeenCopied] = useState(false);

  return (
    <>
      <div className={styles.shareSummary}>
        <div className={styles.shareSummaryLeft}>
          <Button
            tiny
            secondary
            onClick={() => {
              submitUnfinishedRequest(data, (result) => {
                if (result.success) {
                  const url = `${window.location.origin}/yhteenveto/${result.hash}`;
                  navigator.clipboard.writeText(url);

                  setLinkHasBeenCopied(true);

                  setTimeout(() => {
                    setLinkHasBeenCopied(false);
                  }, 3000);
                }
              });
            }}
          >
            {t("copy_link_to_share")}
          </Button>
        </div>
        <div className={styles.shareSummaryRight}>
          {t("share_link_description")}
        </div>
      </div>
      <AnimatePresence>
        {linkHasBeenCopied && (
          <Modal
            closeModal={() => {
              setLinkHasBeenCopied(false);
            }}
          >
            <span className='h2'>{t("copied")}</span>
          </Modal>
        )}
      </AnimatePresence>
    </>
  );
};

export default ShareSummary;
