import Button from "components/Button";
import React, { useCallback, useMemo, useState } from "react";
import useStore from "utils/useStore";
import EditButton from "./editButton";

import styles from "./summary.module.css";

import constants from "constants/fi.json";

const useEditing = (defaultIsEditing: boolean = false) => {
  const [isEditing, setIsEditing] = useState<boolean>(defaultIsEditing);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const toggleEditing = useCallback(() => {
    setIsEditing((oldValue) => !oldValue);
  }, []);

  const Edit = () => (
    <EditButton onClick={() => toggleEditing()} isEditing={isEditing} />
  );

  const UnEditable = ({ children }: { children: React.ReactNode }) => {
    return (
      <div onClick={() => setModalOpen(true)} className={styles.editingValue}>
        {children}
      </div>
    );
  };

  const closeModal = () => setModalOpen(false);

  const ConfirmCancel = ({
    confirm,
    disabled,
  }: {
    confirm?: () => void;
    disabled?: boolean;
  }) => {
    if (!isEditing) {
      return null;
    }

    const classes = [styles.confirmCancel];

    if (disabled) {
      classes.push(styles.disabled);
    }

    return (
      isEditing && (
        <div className={classes.join(' ')}>
          <Button
            tiny
            secondary
            onClick={() => {
              setIsEditing(false);
            }}
          >
            {constants.cancel}
          </Button>
          <Button
            tiny
            disabled={disabled}
            onClick={() => {
              if (disabled) {
                return;
              }

              if (confirm) {
                confirm();
              }
              setIsEditing(false);
            }}
          >
            {constants.save}
          </Button>
        </div>
      )
    );
  };

  return [
    isEditing,
    Edit,
    ConfirmCancel,
    modalOpen,
    UnEditable,
    closeModal,
    setIsEditing,
  ] as const;
};

export const useEditableInput = () => {
  type EditableValue = { valueKey: string; value: string | null };
  interface InputType extends EditableValue {
    validate?: (value: string) => boolean;
    placeholder?: string;
  }

  let editedValues: EditableValue[] = [];

  const setUserSelection = useStore((state) => state.setUserSelection);

  const addEditetValue = (editedValue: EditableValue) => {
    const newValues = editedValues.filter(
      (oldValue) => oldValue.valueKey !== editedValue.valueKey
    );

    newValues.push(editedValue);

    editedValues = newValues;
  };

  const Input = ({
    valueKey,
    value,
    validate,
    placeholder,
  }: InputType) => {
    const [localValue, setLocalValue] = useState<string | null>(value);

    const isValid = useMemo(() => {
      if (!validate) {
        return true;
      }

      return validate(localValue || "");
    }, [localValue, validate]);
  
    const classes = [styles.editingValue];

    if (!isValid) {
      classes.push(styles.error);
    }

    return (
      <input
        className={classes.join(" ")}
        placeholder={placeholder}
        onChange={(e) => {
          setLocalValue(e.target.value);
          addEditetValue({ valueKey, value: e.target.value });
        }}
        defaultValue={value || undefined}
      />
    );
  };

  const save = () => {
    editedValues.forEach(({ valueKey, value }) => {
      setUserSelection(valueKey, value);
    });
  };

  return [Input, save] as const;
};

export default useEditing;
