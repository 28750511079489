import React from "react";
import { AnimatePresence, motion } from "framer-motion";

import styles from "./Button.module.css";

interface Props {
  children: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  disabled?: boolean;
  dark?: boolean;
  uppercase?: boolean;
  secondary?: boolean;
  fullWidth?: boolean;
  topGap?: boolean;
  header?: boolean;
  tiny?: boolean;
  id?: string;
  cover?: boolean;
}

interface ButtonsProps {
  children: React.ReactNode;
  insideModal?: boolean;
  stack?: boolean;
  tiny?: boolean;
  fullWidth?: boolean;
}

export const Buttons = ({ children, insideModal, stack, fullWidth }: ButtonsProps) => {
  const classes = [styles.buttons];

  if (insideModal) {
    classes.push(styles.insideModal);
  }

  if (stack) {
    classes.push(styles.stack);
  }

  if (fullWidth) {
    classes.push(styles.fullWidth);
  }

  return <div className={classes.join(" ")}>{children}</div>;
};

const Button = ({
  children,
  onClick,
  disabled,
  dark,
  uppercase,
  secondary,
  fullWidth,
  topGap,
  header,
  tiny,
  id,
  cover,
}: Props) => {
  const classes = [styles.button];

  if (dark) {
    classes.push(styles.dark);
  }

  if (uppercase) {
    classes.push(styles.uppercase);
  }

  if (disabled) {
    classes.push(styles.disabled);
  }

  if (secondary) {
    classes.push(styles.secondary);
  }

  if (fullWidth) {
    classes.push(styles.fullWidth);
  }

  if (topGap) {
    classes.push(styles.topGap);
  }

  if (header) {
    classes.push(styles.header);
  }

  if (tiny) {
    classes.push(styles.tiny);
  }
  if (cover) {
    classes.push(styles.cover);
  }

  return (
    <AnimatePresence mode='wait'>
      <div
        onClick={onClick && !disabled ? onClick : undefined}
        className={classes.join(" ")}
        id={id}
      >
        <motion.span
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.25 }}
        >
          {children}
        </motion.span>
      </div>
    </AnimatePresence>
  );
};

export default Button;
