import React, { useState } from "react";

import Button from "components/Button";
import EmbeddedImage from "components/EmbeddedImage";
import ModalNavigation from "components/ModalNavigation";
import RadioList from "components/RadioList";

import styles from "./PackModalContent.module.css";

import constants from "constants/fi.json";
import { WP_REST_API_Post } from "types/WP";

import useAgency from "utils/useAgency";
import { SelectableItem, UserSelection } from "utils/useStore";
import analytics from "utils/analytics";

interface Props {
  closeModal: React.MouseEventHandler<HTMLDivElement>;
  select: (coffinFlower: UserSelection) => void;
  selectSize: (coffinFlowerSizes: UserSelection) => void;
  flowers: any;
  browse: (direction: 1 | -1) => void;
  coffinFlowerSizes?: WP_REST_API_Post[];
  selectedSize: UserSelection;
}

const useFlowerBasePrice = (flowerId: number) => {
  const [agencyCoffinFlowerPrices] = useAgency(["acf.agency_coffin_flowers"]);

  if (!Array.isArray(agencyCoffinFlowerPrices)) {
    return "";
  }

  const flowerSize = agencyCoffinFlowerPrices.find(
    (x: any) => x["coffin_flowers"] === flowerId
  );

  if (!flowerSize) {
    return "";
  }

  return String(flowerSize.price["price_base"]);
};

const useMappedSizes = (
  flowers?: WP_REST_API_Post,
  coffinFlowerSizes?: WP_REST_API_Post[]
) => {
  const [agencyCoffinFlowerPrices] = useAgency(["acf.agency_coffin_flowers"]);

  if (
    !flowers ||
    !Array.isArray(agencyCoffinFlowerPrices) ||
    !Array.isArray(coffinFlowerSizes) ||
    !Array.isArray(flowers["flower-size"])
  ) {
    return [];
  }

  const flowerSize = agencyCoffinFlowerPrices.find(
    (x: any) => x["coffin_flowers"] === flowers.id
  );

  if (!flowerSize || !flowerSize.price) {
    return [];
  }

  const prices = flowerSize.price["flower_size_prices"];

  return flowers["flower-size"].reduce((acc, currentSize) => {
    const priceItem = prices.find((x: any) => x["flower_size"] === currentSize);

    const flowerSize = coffinFlowerSizes.find(({ id }) => id === currentSize);

    if (flowerSize && priceItem) {
      acc.push({
        title: `${flowerSize.name}, ${priceItem.price} €`,
        id: flowerSize.id,
        price: String(priceItem.price),
      });
    }

    return acc;
  }, []);
};

const Modal = ({
  flowers,
  browse,
  select,
  selectSize,
  closeModal,
  coffinFlowerSizes,
  selectedSize,
}: Props) => {
  const [userSize, setUserSize] = useState<UserSelection>(selectedSize);
  const sizeItems = useMappedSizes(
    flowers,
    coffinFlowerSizes
  ) as SelectableItem[];

  const title = flowers.title.rendered;

  const price = useFlowerBasePrice(flowers.id);

  return (
    <div className={styles.wrapper}>
      {title && (
        <div className={styles.toprow}>
          <span className={[styles.title, "h2"].join(" ")}>{title}</span>
          {price && <span className={styles.price}>, {price} €</span>}
        </div>
      )}
      {flowers.content.rendered && (
        <div
          className={styles.content}
          dangerouslySetInnerHTML={{ __html: flowers.content.rendered }}
        />
      )}

      <EmbeddedImage post={flowers} size="medium" zoomable />

      <RadioList
        select={setUserSize}
        selected={userSize}
        options={sizeItems}
        title={constants.coffin_flower_size}
      />

      <Button
        fullWidth
        onClick={(e) => {
          analytics.pushSelection({
            selectionTitle: "Coffin flower",
            selectionValue: title,
          });

          if (userSize) {
            analytics.pushSelection({
              selectionTitle: "Coffin flower sizes",
              selectionValue: userSize.title,
            });
          }

          select({
            id: flowers.id,
            title: title,
            price: price,
          });

          selectSize(userSize);

          closeModal(e);
        }}
        topGap
      >
        {constants.choose_and_continue}
      </Button>

      <ModalNavigation browse={browse} />
    </div>
  );
};

export default Modal;
