import React from "react";
import styles from "./Bottom.module.css";

interface Props {
  children: React.ReactNode;
  stackOnDesktop?: boolean;
}

const Bottom = ({ children, stackOnDesktop }: Props) => {
  const classes = [styles.bottom];

  if (stackOnDesktop) {
    classes.push(styles.stackOnDesktop);
  }

  return <div className={classes.join(" ")}>{children}</div>;
};

export default Bottom;
