import { useState, useEffect } from "react";
import { AnimatePresence } from "framer-motion";

import { useNavigate, useParams } from "react-router-dom";

import useStore from "utils/useStore";

import Button from "components/Button";
import usePhase from "utils/usePhase";

import constants from "constants/fi.json";

import styles from "./summary.module.css";
import sendOffer from "utils/sendOffer";

import Previous from "components/Previous";

import Transportation from "./transportation";
import Burial from "./burial";
import Coffin from "./coffin";
import Contact from "./contact";
import Preparation from "./preparation";
import Flowers from "./flowers";
import Urn from "./urn";
import ShareSummary from "./ShareSummary";

import Bottom from "components/Bottom";
import Modal from "components/Modal";

import fetchUnfinishedRequest from "utils/fetchUnfinishedRequest";

const Summary = () => {
  const t = usePhase("summary");
  const store = useStore();
  const setFromFetch = useStore((state) => state.setFromFetch);

  let navigate = useNavigate();

  let { hash } = useParams();

  const [shareDataLoaded, setShareDataLoaded] = useState(false);
  const [alertModalIsOpen, setAlertModalIsOpen] = useState(false);

  const orderedNameAndEmailExists = store.ordererEmail && store.ordererName;

  useEffect(() => {
    if (hash && !shareDataLoaded) {
      fetchUnfinishedRequest(hash, (result) => {
        if (result.success) {
          setShareDataLoaded(true);
          setFromFetch(result.submission);
        } else {
          navigate("/");
        }
      });
    }
  }, [hash, setFromFetch, setShareDataLoaded, shareDataLoaded, navigate]);

  if (hash && !shareDataLoaded) {
    return <div>{/* <Loading /> */}</div>;
  }

  const data = {
    version: 1,
    cost: store.calculateCost(),
    agency: store.agency,
    agencyIsForced: store.agencyIsForced,
    attendTransport: store.attendTransport,
    blessingPlace: store.blessingPlace,
    burial: store.burial,
    burialPlace: store.burialPlace,
    burialWishes: store.burialWishes,
    cemetery: store.cemetery,
    clothing: store.clothing,
    coffin: store.coffin,
    coffinFlower: store.coffinFlower,
    coffinFlowerSizes: store.coffinFlowerSizes,
    coffinOptions: store.coffinOptions,
    currentMunicipality: store.currentMunicipality,
    datetime: store.datetime,
    deceasedChurch: store.deceasedChurch,
    deceasedName: store.deceasedName,
    deceasedSocialId: store.deceasedSocialId,
    deceasedWishes: store.deceasedWishes,
    guestFlower: store.guestFlower,
    eventType: store.eventType,
    hospital: store.hospital,
    moreInfoAboutBurial: store.moreInfoAboutBurial,
    oldPlaceInfo: store.oldPlaceInfo,
    ordererEmail: store.ordererEmail,
    ordererName: store.ordererName,
    ordererPhone: store.ordererPhone,
    ordererRelationship: store.ordererRelationship,
    package: store.package,
    servicesOffer: store.servicesOffer,
    stoneOffer: store.stoneOffer,
    targetMunicipality: store.targetMunicipality,
    transportationCost: store.transportationCost,
    transportationOutOfBounds: store.transportationOutOfBounds,
    urn: store.urn,
    urnFlower: store.urnFlower,
    whoReserves: store.whoReserves,
  };

  return (
    <>
      <div className="App-content">
        <div className={styles.title}>
          <h2 className="h2">{constants.summary}</h2>
        </div>

        <Transportation />
        <Burial />
        <Coffin />
        <Urn />
        <Preparation />
        <Flowers />
        <Contact />

        <ShareSummary data={data} />

        <div className={styles.total}>
          <div className={[styles.toprow, "h3"].join(" ")}>
            <div className={styles.totaltitle}>{t("in_total")}</div>
            <div className={styles.totalamount}>{data.cost} €</div>
          </div>
          <div className={styles.total}>{t("disclaimer")}</div>
        </div>
      </div>
      <Bottom>
        <AnimatePresence>
          {alertModalIsOpen && (
            <Modal
              closeModal={() => {
                setAlertModalIsOpen(false);
              }}
            >
              <div className={styles.alertModalTitle}>
                {t("remember_to_add_orderer_email")}
              </div>
            </Modal>
          )}
        </AnimatePresence>
        <Button
          onClick={() => {
            if (orderedNameAndEmailExists) {
              sendOffer(data, (res) => {
                navigate("/kiitos");
              });
            } else {
              setAlertModalIsOpen(true);
            }
          }}
          id="button-send-offer"
        >
          {constants.send_offer_request}
        </Button>
        <Previous to="/yhteenveto" />
      </Bottom>
    </>
  );
};

export default Summary;
