import React, { useState } from "react";
import { SelectableItem, UserSelection } from "utils/useStore";

import PackModalContent from "./PackModalContent";
import useMappedCoffinPrices from "./useMappedCoffinPrices";


const useCoffins = () => {
  const coffins = useMappedCoffinPrices();

  const [openModalIndex, setOpenModalIndex] = useState<number | null>(null);

  const ModalContent = ({
    closeModal,
    setCoffin,
    setCoffinOptions,
    selected,
  }: {
    closeModal: React.MouseEventHandler<HTMLDivElement>;
    setCoffin: (coffin: UserSelection) => void;
    setCoffinOptions: (coffinOption: SelectableItem[] | null) => void;
    selected: SelectableItem[] | null;
  }) => (
    <>
      {openModalIndex !== null && (
        <PackModalContent
          coffin={coffins[openModalIndex]}
          closeModal={closeModal}
          setCoffin={setCoffin}
          setCoffinOptions={setCoffinOptions}
          selected={selected}
          browse={(newDirection: 1 | -1) => {
            const newIndex = openModalIndex + newDirection;
            if (newIndex >= 0 && newIndex < coffins.length) {
              setOpenModalIndex(newIndex);
            }
          }}
        />
      )}
    </>
  );

  return [coffins, setOpenModalIndex, openModalIndex, ModalContent] as const;
};

export default useCoffins;
