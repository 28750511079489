import React, { useState } from "react";

import { useData } from "utils/getData";
import { UserSelection } from "utils/useStore";

import CoffinFlowersModalContent from "./CoffinFlowersModalContent";
import useMappedCoffinFlowerPrices from "./useMappedCoffinFlowerPrices";

const useCoffinFlowers = () => {
  const coffinFlowerSizes = useData("flower-size");
  const flowers = useMappedCoffinFlowerPrices();

  const [openModalIndex, setOpenModalIndex] = useState<number | null>(null);

  const ModalContent = ({
    closeModal,
    setCoffinFlower,
    setCoffinFlowerSize,
    selectedSize
  }: {
    closeModal: React.MouseEventHandler<HTMLDivElement>;
    setCoffinFlower: (coffinFlower: UserSelection) => void;
    setCoffinFlowerSize: (coffinFlowerSizes: UserSelection) => void;
    selectedSize: UserSelection;
  }) => (
    <>
      {openModalIndex !== null && (
        <CoffinFlowersModalContent
          flowers={flowers[openModalIndex]}
          closeModal={closeModal}
          select={setCoffinFlower}
          coffinFlowerSizes={coffinFlowerSizes}
          selectSize={setCoffinFlowerSize}
          browse={(newDirection: 1 | -1) => {
            const newIndex = openModalIndex + newDirection;
            if (newIndex >= 0 && newIndex < flowers.length) {
              setOpenModalIndex(newIndex);
            }
          }}
          selectedSize={selectedSize}
        />
      )}
    </>
  );

  return [flowers, setOpenModalIndex, openModalIndex, ModalContent] as const;
};

export default useCoffinFlowers;
